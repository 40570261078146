import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';


@Component({
  selector: 'app-sidebar-nav-link',
  templateUrl: 'app-sidebar-nav-link.component.html',
  styleUrls: ['app-sidebar-nav-link.component.scss']
})
export class AppSidebarNavLinkComponent {
  @HostBinding('class.nav-link') navLinkClass = false;
  @HostBinding('class.active') activeClass = false;

  @Input() url;
  @Input() disabled = false;
  @Input() queryParams: any = {};
  @Input() iconClass = null;
  @Input() exact = true;

  @Output() handleOnClick = new EventEmitter();

  get hasIcon() {
    return Boolean(this.iconClass);
  }
}
