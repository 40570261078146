import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ui-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss', '../ui-elements.scss']
})
export class UILabelComponent implements OnInit {
  @Input() controlId = null;
  @Input() required = true;

  constructor() { }

  ngOnInit() {
  }

}
