import { Component, HostBinding } from '@angular/core';
import { AuthService } from '../../../modules/auth/auth.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: 'app-sidebar.component.html',
  styleUrls: ['app-sidebar.component.scss']
})
export class AppSidebarComponent {
  @HostBinding('class.sidebar') sidebarClass = true;

  constructor(
    private authService: AuthService,
  ) {
  }

  handleLogout() {
    this.authService.logout();
  }
}
