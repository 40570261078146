import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'labelValuePair'
})
export class LabelValuePairPipe implements PipeTransform {
  transform(value: any, options: any[] = [], fallback: string): string {

    if (typeof value !== 'object' || value === null) {
      const selectedOption = options.find(option => option.value === value);

      if (selectedOption) {
        return selectedOption.label;
      } else {
        return fallback ? fallback : value && value.toString();
      }
    }

    return (value || {} as any).label;
  }
}
