import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-sidebar-nav-title',
  templateUrl: 'app-sidebar-nav-title.component.html',
  styleUrls: ['app-sidebar-nav-title.component.scss']
})
export class AppSidebarNavTitleComponent {

  @HostBinding('class.nav-item') navItemClass = true;
}
