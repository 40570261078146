import { Component, EventEmitter, forwardRef, HostBinding, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorEnabler } from '../../entities/control-value-accessor-enabler';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UIToggleButtonComponent),
  multi: true
}

@Component({
  selector: 'app-ui-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss', '../ui-elements.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class UIToggleButtonComponent extends ControlValueAccessorEnabler implements OnInit {
  private defaultInputIdentifier = 'ui-form-control' + new Date().getTime().toString();

  @Input() controlId = null;
  @Input() name = this.defaultInputIdentifier;
  @Output() isSelectedChange = new EventEmitter<boolean>();

  constructor() {
    super();
  }

  ngOnInit() {
  }

  toggleToggleSelection() {
    const isSelected = Boolean(this.value);
    this.value = !isSelected;
    this.isSelectedChange.emit(isSelected);
  }

}
