import { Component, HostBinding, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LabelValuePair } from '../../components/interfaces/label-value-pair';
import { TranslateService } from '@ngx-translate/core';
import { STORAGE } from '../../constants';
import { AuthService } from '../../../modules/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @HostBinding('class.header') headerClass = true;
  formSearch: FormGroup;

  languages: Array<LabelValuePair> = [
    { label: 'shared.layouts.header.english', value: 'en' },
    { label: 'shared.layouts.header.traditionalChinese', value: 'zh-tw' },
    { label: 'shared.layouts.header.simplifiedChinese', value: 'zh-cn' }
  ];
  currentLanguage = 'en';
  username = '';

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.username = this.authService.currentCredentials.userName;
    this.formSearch = this.fb.group({
      searchText: ['']
    });
  }

  onSelectedLanguage(language: string) {
    this.translate.use(language || 'en');
  }

  onSubmit() {

  }

}
