import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NoAuthGuard } from './core/guards';
import { AuthGuard } from './core/guards';
import { LayoutComponent } from './shared/layouts';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/posts/list',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: './modules/auth/auth.module#AuthModule',
    canActivate: [ NoAuthGuard ],
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: './modules/dashboard/dashboard.module#DashboardModule',
        canActivate: [ AuthGuard ],
        data: { breadcrumb: 'Dashboard' }
      },
      {
        path: 'posts',
        loadChildren: './modules/posts/posts.module#PostsModule',
        canActivate: [ AuthGuard ],
        data: { breadcrumb: 'Posts' }
      },
      {
        path: 'news-feed',
        loadChildren: './modules/news-feed/news-feed.module#NewsFeedModule',
        canActivate: [ AuthGuard ],
        data: { breadcrumb: 'News Feed Crawler' }
      },
      {
        path: 'categories',
        loadChildren: './modules/categories/categories.module#CategoriesModule',
        canActivate: [ AuthGuard ],
        data: { breadcrumb: 'Categories' }
      },
      {
        path: 'notification',
        loadChildren: './modules/notification/notification.module#NotificationModule',
        canActivate: [ AuthGuard ],
        data: { breadcrumb: 'Notifications' }
      },
      {
        path: 'administrator',
        loadChildren: './modules/administrator/administrator.module#AdministratorModule',
        canActivate: [ AuthGuard ],
        data: { breadcrumb: 'Administrator Accounts' }
      },
      {
        path: 'access-rights',
        loadChildren: './modules/access-rights/access-rights.module#AccessRightsModule',
        canActivate: [ AuthGuard ],
        data: { breadcrumb: 'Access Right Management' }
      },
      {
        path: 'settings',
        loadChildren: './modules/settings/settings.module#SettingsModule',
        canActivate: [ AuthGuard ],
        data: { breadcrumb: 'General Settings' }
      },
      {
        path: 'activity-logs',
        loadChildren: './modules/activity-logs/activity-logs.module#ActivityLogsModule',
        canActivate: [ AuthGuard ],
        data: { breadcrumb: 'Activity Logs' }
      },
      {
        path: 'marquee',
        loadChildren: './modules/marquee-message/marquee-message.module#MarqueeMessageModule',
        canActivate: [ AuthGuard ],
        data: { breadcrumb: 'Marquess message' }
      },
    ]
  },
  {
    path: 'errors',
    loadChildren: './modules/errors/errors.module#ErrorsModule',
  },
  {
    path: '**',
    redirectTo: '/errors/404',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
