import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessorEnabler } from '../../entities/control-value-accessor-enabler';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UIDatetimePickerComponent),
  multi: true
};

@Component({
  selector: 'app-ui-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class UIDatetimePickerComponent extends ControlValueAccessorEnabler implements OnInit {

  @Input() controlPlaceHolder = '';
  @Input() controlId = '';
  @Input() name: string;
  @Input() required = false;
  @Input() disabled = false;
  @Input() readonly = false;

  @Output() inputChange: EventEmitter<any> = new EventEmitter();

  today: Date = new Date();
  minDate = new Date(new Date().setMinutes(this.today.getMinutes() + 5));

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
