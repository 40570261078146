import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-content',
  template: `<ng-content></ng-content>`
})
export class AppContentComponent {

  @HostBinding('class.content') appContentClass = true;
}
