
export class Common {
  static showLoading() {
    document.getElementById('app-loading').style.display = 'block';
  }

  static hideLoading() {
    document.getElementById('app-loading').style.display = 'none';
  }

  static getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve((<string>reader.result).split(',')[1]);
      reader.onerror = error => reject(error);
    });
  }
}
