import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss']
})
export class DropdownButtonComponent implements OnInit {

  @Input() readonly = false;
  @Input() enable = false;
  @Output() statusChange = new EventEmitter<{}>();

  constructor() { }

  ngOnInit() {
  }

  onDisable() {
    this.statusChange.emit({ enable: false });
  }

  onEnable() {
    this.statusChange.emit({ enable: true });
  }
}
