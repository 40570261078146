import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { STORAGE } from '../../shared/constants';

@Pipe({
  name: 'appDateFormat'
})
export class DateFormatPipe implements PipeTransform {

  dateFormat = JSON.parse(localStorage.getItem(STORAGE.CREDENTIAL_STORAGE)).dateFormat;
  timeZone = String(JSON.parse(localStorage.getItem(STORAGE.CREDENTIAL_STORAGE)).timeZone);

  constructor(
    private datePipe: DatePipe
  ) {
  }

  transform(value: any): any {
    return this.datePipe.transform(value, this.dateFormat, this.timeZone);
  }

}
