import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as camelcaseKeys from 'camelcase-keys';
import { Credential } from '../models/credentials';
import { STORAGE } from '../../shared/constants';

export function encryptParam(params) {
  for (const key in params) {
    if (params.hasOwnProperty(key)) {

      if (params[key] !== null && params[key] !== '' && params[key] !== undefined && key !== 'sort') {
        params[key] = encodeURIComponent(params[key]);
      }

    }
  }
  return params;
}

@Injectable({ providedIn: 'root' })
export class ApplicationHttpClient {
  constructor(public http: HttpClient) {
  }

  get<T>(endpoint: string, options: any = {}): Observable<any> {

    if (!options.observe) {
      options.observe = 'response';
    }

    return this.http.get<T>(endpoint, options);
  }

  post<T>(endpoint: string, body: any | null, options: any = {}): Observable<any> {

    options.observe = 'response';
    return this.http.post<T>(endpoint, body, options)
      .pipe(map((response: any) => {
        ApplicationHttpClient.updateToken(response);

        return camelcaseKeys(response.body || {}, { deep: true });
      }));
  }

  put<T>(endpoint: string, body: any | null, options: any = {}): Observable<any> {

    options.observe = 'response';

    return this.http.put<T>(endpoint, body, options)
      .pipe(map((response: any) => {
        ApplicationHttpClient.updateToken(response);

        if (response.body) {
          return camelcaseKeys(response.body, { deep: true });
        } else {
          return response.body;
        }
      }));
  }

  patch<T>(endpoint: string, body: any | null, options: any = {}): Observable<any> {

    options.observe = 'response';

    return this.http.patch<T>(endpoint, body, options)
      .pipe(map((response: any) => {
        ApplicationHttpClient.updateToken(response);

        if (response.body) {
          return camelcaseKeys(response.body, { deep: true });
        } else {
          return response.body;
        }
      }));
  }

  delete<T>(endpoint: string, options: any = {}): Observable<any> {

    options.observe = 'response';

    return this.http.delete<T>(endpoint, options)
      .pipe(map((response: any) => {
        ApplicationHttpClient.updateToken(response);

        return response.body;
      }));
  }

  private static updateToken(response) {
    if (response.headers.get('access_token')) {
      const accessToken = response.headers.get('access_token');

      const credential: Credential = JSON.parse(localStorage.getItem(STORAGE.CREDENTIAL_STORAGE));
      credential.accessToken = accessToken;
      localStorage.setItem(STORAGE.CREDENTIAL_STORAGE, JSON.stringify(credential));
    }
  }

}

export function applicationHttpClientCreator(http: HttpClient) {
  return new ApplicationHttpClient(http);
}
