import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-ui-button-link',
  templateUrl: 'button-link.component.html',
  styleUrls: ['../ui-elements.scss', 'button-link.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UIButtonLinkComponent implements OnInit {
  @Input() url;
  @Input() controlId = '';
  @Input() query: any = {};
  @Input() disabled = false;

  constructor() { }

  ngOnInit() {
  }

}
