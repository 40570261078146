import { Observable, Subject } from 'rxjs';
import { ComponentRef } from '@angular/core';
import { ModalContainerComponent } from '../components/modal-container/modal-container.component';
import { Modal } from './modal.model';


export class ModalRef {
  private result = new Subject<{ [p: string]: number | string | boolean }>();

  constructor(
    private modalContainer: ComponentRef<ModalContainerComponent>,
    private modal: ComponentRef<Modal>
  ) {

    this.modal.instance.modalInstance = this;
  }

  close(output: { [p: string]: number | string | boolean }) {
    this.result.next(output);
    this.destroy();
  }

  dismiss(output: { [p: string]: number | string | boolean }) {
    this.result.error(output);
    this.destroy();
  }

  onResult(): Observable<{ [p: string]: number | string | boolean }> {
    return this.result.asObservable();
  }

  private destroy() {
    this.modal.destroy();
    this.modalContainer.destroy();
    this.result.complete();
  }
}

