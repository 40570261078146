
import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  HostListener
} from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[ngModel][maxLength]',
  providers: [ NgModel ]
})
export class MaxLengthDirective implements OnInit {
  @Input() maxLength: number;

  constructor(private elementRef: ElementRef, private ngModel: NgModel) {
  }

  ngOnInit() {
  }

  @HostListener('keyup', ['$event'])
  onChange(event) {
    if (!this.maxLength || event.isComposing) {
      return;
    }

    const value = event.target.value;
    const selectionStart = event.target.selectionStart;
    const selectionEnd = event.target.selectionEnd;

    event.target.value = this.getMaxValue(value);
    this.ngModel.update.emit(this.getMaxValue(value));
    event.target.setSelectionRange(selectionStart, selectionEnd);
  }

  private getMaxValue(value) {
    const array = value.split('');

    return array.reduce((total, text) => {
      const str = total + text;

      if ((str || '').length > this.maxLength) {
        return total;
      }

      return str;
    }, '');
  }
}
