import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @HostBinding('class.footer') footerClass = true;

  constructor(
    @Inject(DOCUMENT) private document: any,
  ) { }

  ngOnInit(): void {

  }

}
