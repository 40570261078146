import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { CustomEventBroadcasterService } from '../../services';
import { Subscription } from 'rxjs';
import { CustomEventDetails } from '../../interfaces';
import { SORT_BY } from '../../../constants';
import { TableSortItem } from '../../interfaces/table-sort-item';


@Component({
  selector: '[uiTableHeader]',
  templateUrl: 'table-header.component.html',
  styleUrls: ['ui-table.scss', 'table-header.component.scss']
})
export class UITableHeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() name: string;
  @Input() translation: string;
  @Input() key: string;
  @Input() isSortable = false;
  @Input() isSortReset = false;
  @Input() width: string;
  @Input() sortDefault: string;
  @Input() tableId: string;
  @Output() sortClickedEvent: EventEmitter<TableSortItem> = new EventEmitter();

  private eventBroadcasterSubscription: Subscription;
  private sortDefaultSubscription: Subscription;
  private isSortDefault = false;
  sortBy: string = SORT_BY.DESCENDING;

  constructor(
    private eventBroadcaster: CustomEventBroadcasterService,
  ) {
  }

  ngOnInit(): void {
    this.eventBroadcasterSubscription = this.eventBroadcaster.subscribeFor(
      'uiTable:sorted',
      (eventDetails: CustomEventDetails) => {
        if (this.tableId !== eventDetails.details.tableId) {
          return;
        }

        if (this.key !== eventDetails.details.key) {
          this.sortBy = SORT_BY.DEFAULT;
        }

        if (eventDetails.details.isSorted === SORT_BY.DEFAULT && this.key !== eventDetails.details.key && !this.isSortReset) {
          this.sortBy = SORT_BY.DESCENDING;
        }
      });

    this.sortDefaultSubscription = this.eventBroadcaster.subscribeFor(
      'uiTable:sortDefault',
      (eventDetails: CustomEventDetails) => {
        if (this.tableId !== eventDetails.details.tableId) {
          return;
        }

        if (this.key !== eventDetails.details.key) {
          this.sortClickedEvent.emit({
            field: this.key,
            direction: SORT_BY.DESCENDING
          });
        }
      });

    if (this.isSortReset) {
      this.sortBy = SORT_BY.DEFAULT;
    }

    this.updateSort();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sortDefault && changes.sortDefault.currentValue) {
      this.updateSort();
    }
  }

  ngOnDestroy(): void {
    this.eventBroadcasterSubscription.unsubscribe();
    this.sortClickedEvent.unsubscribe();
  }

  sort() {
    if (!this.isSortable) {
      return;
    }

    this.isSortDefault = false;
    if (this.isSortReset) {
      if (this.sortBy === SORT_BY.DEFAULT) {
        this.sortBy = SORT_BY.DESCENDING;
      } else if (this.sortBy === SORT_BY.DESCENDING) {
        this.sortBy = SORT_BY.ASCENDING;
      } else if (this.sortBy === SORT_BY.ASCENDING) {
        this.isSortDefault = true;
      }
    } else {
      if (this.sortBy === SORT_BY.DEFAULT) {
        this.sortBy = SORT_BY.DESCENDING;
      } else {
        this.sortBy = this.sortBy === SORT_BY.ASCENDING ? SORT_BY.DESCENDING : SORT_BY.ASCENDING;
      }
    }

    this.eventBroadcaster.broadcastEvent(
      'uiTable:sorted',
      {
        details: {
          key: this.key,
          isSorted: this.sortBy,
          tableId: this.tableId
        }
      });

    if (this.isSortDefault) {
      this.eventBroadcaster.broadcastEvent(
        'uiTable:sortedDefault',
        {
          details: {
            key: this.key,
            tableId: this.tableId,
          }
        });
    } else {
      this.sortClickedEvent.emit({
        field: this.key,
        direction: this.sortBy === SORT_BY.DEFAULT ? SORT_BY.DESCENDING : this.sortBy
      });
    }
  }

  private updateSort() {
    if (this.sortDefault) {
      const [sortBy, sortType] = this.sortDefault.split(',');

      if (this.key === sortBy) {
        this.sortBy = sortType;
      } else {
        this.sortBy = SORT_BY.DEFAULT;
      }
    }
  }

}
