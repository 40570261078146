import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';

const STYLE_CLASS = {
  primary: 'ui-button--primary',
  'primary-round': 'ui-button--primary-round',
  secondary: 'ui-button--secondary',
  warning: 'ui-button--warning',
  'warning-dark': 'ui-button--warning-dark',
  blue: 'ui-button--blue',
  light: 'ui-button--light',
  'primary-100': 'ui-button__primary-100',
  'blue-outline': 'ui-button--blue-outline'
};

@Component({
  selector: 'app-ui-button',
  templateUrl: 'button.component.html',
  styleUrls: ['../ui-elements.scss', 'button.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class UIButtonComponent implements OnInit, OnChanges {
  @Input() disabled = false;
  @Input() btnStyle: 'primary' | 'primary-round' | 'warning'| 'warning-dark' | 'secondary' | 'blue' | 'light' | 'blue-outline' = 'primary';
  @Input() type: 'button' | 'submit' = 'button';

  styleClass: string = STYLE_CLASS[this.btnStyle] || null;
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('btnStyle')) {
      this.styleClass = STYLE_CLASS[this.btnStyle] || null;
    }
  }

}
