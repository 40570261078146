import { ApplicationRef, ComponentFactory, ComponentFactoryResolver, Injectable, Type } from '@angular/core';
import { ModalContainerComponent } from './components/modal-container/modal-container.component';
import { Modal } from './models/modal.model';
import { ModalRef } from './models/modal-ref.model';


@Injectable()
export class ModalService {

  private modalContainer: HTMLElement;
  private modalContainerFactory: ComponentFactory<ModalContainerComponent>;

  constructor(
    private applicationRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {

    this.initModalContainerFactory();
  }

  open<T extends Modal>(component: Type<T>, inputs?: { [p: string ]: string | boolean | number }) {
    this.initModalContainerDiv();

    const modalContainerRef = this.applicationRef.bootstrap(this.modalContainerFactory, this.modalContainer);
    const modalComponentRef = modalContainerRef.instance.createModal(component);

    if (inputs) {
      modalComponentRef.instance.onInjectInputs(inputs);
    }

    return new ModalRef(modalContainerRef, modalComponentRef);
  }

  private initModalContainerDiv() {
    this.modalContainer = document.createElement('div');
    document.getElementsByTagName('body')[0].appendChild(this.modalContainer);
  }

  private initModalContainerFactory() {
    this.modalContainerFactory = this.componentFactoryResolver.resolveComponentFactory(ModalContainerComponent);

  }
}

