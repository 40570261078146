import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthService } from './modules/auth/auth.service';
import { Subscription } from 'rxjs';
import { Credential } from './core/models/credentials';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'safety-app-frontend';
  private credentialsSubscription: Subscription;

  constructor(
    private translate: TranslateService,
    private authService: AuthService,
    private permissionsService: NgxPermissionsService
  ) {

    translate.setDefaultLang(environment.defaultLanguage);
  }

  ngOnInit(): void {
    this.credentialsSubscription = this.authService.credentials.subscribe((credential: Credential) => {
      const permissions = credential && credential.isSuperAdmin ? ['SUPERADMIN'] : ['ADMINUSER'];
      this.permissionsService.loadPermissions(permissions);
    });
  }

  ngOnDestroy(): void {
    this.credentialsSubscription.unsubscribe();
  }
}
