import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ui-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UIUserInfoComponent implements OnInit {
  @Input() username = '';

  constructor() { }

  ngOnInit() {
  }

}
