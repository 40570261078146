import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-body',
  template: '<ng-content></ng-content>'
})
export class AppBodyComponent {

  @HostBinding('class.app') appBodyClass = true;
}
