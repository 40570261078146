import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[appTrimText]'
})
export class TrimTextDirective implements OnInit, OnDestroy{
  @Input() debounce = 350;

  private subscription: Subscription;
  private typingEvent = new EventEmitter<string>();

  constructor(
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.subscription = this.typingEvent.asObservable()
      .pipe(debounceTime(this.debounce))
      .subscribe((e) => {
        this.elementRef.nativeElement.value = e.trim();
      });
  }


  @HostListener('keyup', ['$event'])
  onKeyup(e) {
    this.typingEvent.emit(this.elementRef.nativeElement.value);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
