import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TableColumn } from '../../interfaces/table-column';
import { LabelValuePair } from '../../interfaces/label-value-pair';

@Component({
  selector: '[uiTableRow]',
  templateUrl: 'table-row.component.html',
  styleUrls: ['ui-table.scss', 'table-row.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UITableRowComponent implements OnChanges {

  @Input() confirmBeforeChangeStatus;
  @Input() columns: Array<string> = [];
  @Input() columnConfig: { [key: string]: TableColumn } = {};

  @Input() rowItem: { [key: string]: any };
  @Input() dropdownOptions: Array<LabelValuePair> = [];
  @Input() dropdownPlaceHolder: string;
  @Output() selectItemEvent: EventEmitter<any> = new EventEmitter();
  @Output() rowEvent: EventEmitter<{
    action: 'uiTable:selectOption' | 'uiTable:disable' | 'uiTable:enable' | 'uiTable:checkItem',
    value: any
  }> = new EventEmitter();

  onSelectOption(optionValue: string, rowItem: any) {
    this.rowEvent.emit({
      action: 'uiTable:selectOption',
      value: {optionValue, rowItem}
    });
  }

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  onStatusChange(status: { enable: boolean }, rowItem: { [p: string]: any }) {
    if (status.enable) {
      this.rowEvent.emit({action: 'uiTable:enable', value: rowItem});
    } else {
      this.rowEvent.emit({action: 'uiTable:disable', value: rowItem});
    }
  }

  onChangeCheckBox(value: boolean, rowItem: any) {
    this.rowEvent.emit({
      action: 'uiTable:checkItem',
      value: {value, rowItem}
    });
  }

  onCellClicked(column: string, rowItem: { [p: string]: any }) {
    if (this.columnConfig[column].emitRowClick) {
      this.selectItemEvent.emit(rowItem);
    }
  }

  handleCompare(arr1, arr2) {
    const result = [];
    for (let i = 0; i < arr1.length; i++) {
      result.push(arr1[i] === arr2[i]);
    }
    return result.some(item => item);
  }

  handleReadOnly(column: any) {
    if (this.columnConfig[column].isSuperadmin) {
      return false;
    } else {
      const fieldCompareDisable = this.columnConfig[column].fieldCompareDisable && (this.columnConfig[column].fieldCompareDisable)
        .split(',')
        .map(item => this.rowItem[item]);
      const disableCreatedBy = this.columnConfig[column].disableCreateBy && (this.columnConfig[column].disableCreateBy).split(',');
      if (fieldCompareDisable && disableCreatedBy) {
        return this.handleCompare(fieldCompareDisable, disableCreatedBy);
      }
      return false;
    }
  }
}
