import { Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { ControlValueAccessorEnabler } from '../../entities/control-value-accessor-enabler';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UIInputComponent),
  multi: true
};

@Component({
  selector: 'app-ui-input',
  templateUrl: 'input.component.html',
  styleUrls: ['../ui-elements.scss', 'input.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class UIInputComponent extends ControlValueAccessorEnabler implements OnInit {
  private defaultInputIdentifier = 'ui-form-control' + new Date().getTime().toString();

  @Input() controlId = null;
  @Input() controlPlaceHolder = '';
  @Input() readOnly = false;
  @Input() type: 'text' | 'number' | 'password' = 'text';
  @Input() pattern: any = '';
  @Input() disabled = false;
  @Input() required = false;
  @Input() name: string = this.defaultInputIdentifier;
  @Input() round = false;
  @Input() icon = '';
  @Input() maxLength = null;
  @Input() className = '';

  @Output() inputChange: EventEmitter<any> = new EventEmitter();

  ngControl: NgControl;
  usedCharacters = 0;

  constructor(private injector: Injector) {
    super();
  }

  ngOnInit() {
    this.ngControl = this.injector.get(NgControl);
    setTimeout(() => {
      this.ngControl.valueChanges.subscribe((value) => {
        this.calculateRemainLength();
      });
    });
  }

  onChange(value) {
    this.inputChange.emit(value);

    if (this.displayRemainingLength) {
      this.calculateRemainLength();
    }

  }

  calculateRemainLength() {
    this.usedCharacters = (this.ngControl.control.value || '').length;
  }

  displayRemainingLength() {
    return Boolean(this.maxLength);
  }

}
