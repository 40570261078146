import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../core/http/http-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { Credential } from '../../core/models/credentials';
import { map } from 'rxjs/operators';
import { STORAGE } from '../../shared/constants';


@Injectable({providedIn: 'root'})
export class AuthService {
  private credentialSubject: BehaviorSubject<Credential>;
  public credentials: Observable<Credential>;

  constructor(
    private http: ApplicationHttpClient
  ) {
    this.credentialSubject = new BehaviorSubject<Credential>(JSON.parse(localStorage.getItem(STORAGE.CREDENTIAL_STORAGE)));
    this.credentials = this.credentialSubject.asObservable();
  }

  public login(username: string, password: string): Observable<Credential> {
    return this.http.post<any>('/api/admin/account/login', {username, password})
      .pipe(map(data => this.storeCredentials(data)));
  }

  public get currentCredentials(): Credential {
    return JSON.parse(localStorage.getItem(STORAGE.CREDENTIAL_STORAGE));
  }

  private storeCredentials(data) {
    const credentials: Credential = {
      accessToken: data.accessToken,
      expiresIn: data.expiresIn,
      tokenType: data.tokenType,
      email: data.email,
      isSuperAdmin: data.isSuperAdmin === 'True',
      userId: data.userId,
      userName: data.userName,
      timeFormat: Number(data.timeFormat),
      dateFormat: data.dateFormat,
      timeZone: Number(data.timeZone)
    };

    const date = new Date();

    localStorage.setItem(STORAGE.REFRESH_TOKEN_TIME, date.toString());
    localStorage.setItem(STORAGE.CREDENTIAL_STORAGE, JSON.stringify(credentials));

    this.credentialSubject.next(credentials);
    return credentials;
  }

  public logout() {
    // this.http.post<any>('/logout', null).toPromise();

    localStorage.removeItem(STORAGE.CREDENTIAL_STORAGE);
    localStorage.removeItem(STORAGE.REFRESH_TOKEN_TIME);

    this.credentialSubject.next(null);
  }

  public forgotPassword(email): Observable<any> {
    return this.http.post('/api/accounts/admin/forgot-password', {email}, {responseType: 'text'});
  }

  public resetPassword(data): Observable<any> {
    return this.http.post('/api/accounts/admin/reset-password', data, {responseType: 'text'});
  }
}
