import {
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges, ViewChild
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ui-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class UIToastComponent implements OnInit, OnChanges {
  @Input() showed;
  @Output() showedChange = new EventEmitter();
  @Input() duration;
  @Input() action: 'create' | 'edit' = 'create';
  @Input() url: string;
  @Input() content: string;
  @ViewChild('toast') toast: ElementRef;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.showed) {
      this.hideToast();
    }
  }

  hideToast() {
    this.toast.nativeElement.classList.add('show-toast');
    const setTimeoutId = setTimeout(() => {
      this.showedChange.emit(false);
      this.toast.nativeElement.classList.remove('show-toast');
      if (this.action === 'create') {
        this.router.navigate([this.url]).then(() => {});
      }
      clearTimeout(setTimeoutId);
    }, Number(this.duration));
  }
}
