import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorEnabler } from '../../entities/control-value-accessor-enabler';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UIMultiSelectComponent),
  multi: true
};

@Component({
  selector: 'app-ui-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class UIMultiSelectComponent extends ControlValueAccessorEnabler implements OnInit {

  private defaultInputIdentifier = 'ui-form-control' + new Date().getTime().toString();

  dropdownSettings = {};
  @Input() idField = '';
  @Input() textField = '';
  @Input() selectAll = '';
  @Input() deSelectAll = '';
  @Input() dropdownList = [];
  @Input() controlId = null;
  @Input() controlLabel = null;
  @Input() controlLabelHidden = true;
  @Input() controlPlaceHolder = '';
  @Input() readOnly = false;
  @Input() type: 'text' | 'number' | 'password' = 'text';
  @Input() pattern: any = '';
  @Input() disabled = false;
  @Input() required = false;
  @Input() name: string = this.defaultInputIdentifier;
  @Input() round = false;
  @Input() className = '';
  @Output() inputChange = new EventEmitter();

  constructor() {
    super();
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: this.idField,
      textField: this.textField,
      selectAllText: this.selectAll,
      unSelectAllText: this.deSelectAll
    };
  }

  onChange(value) {
    this.inputChange.emit(value);
  }

  onItemSelect(item: any) {
    console.log(item);
  }

  onSelectAll(items: any) {
    console.log(items);
  }

  onDeSelect(item: any) {
    console.log(item);
  }

  onDeSelectAll(item: any) {
    console.log(item);
  }

}
