import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../../modules/auth/auth.service';


@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentCredentials = this.authService.currentCredentials;

    if (currentCredentials && currentCredentials.accessToken) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${currentCredentials.accessToken}` }
      });
    }

    return next.handle(request);
  }
}
