import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ControlValueAccessorEnabler } from '../../entities/control-value-accessor-enabler';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ApplicationHttpClient } from '../../../../core/http/http-client';
import { Common } from '../../../common';
import { ToastService } from '../../../services/toast.service';
import { IMAGE_BASE64 } from '../../../constants';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UIRichtexteditorComponent),
  multi: true
};

@Component({
  selector: 'app-ui-richtexteditor',
  templateUrl: './richtexteditor.component.html',
  styleUrls: ['./richtexteditor.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class UIRichtexteditorComponent extends ControlValueAccessorEnabler implements OnInit, AfterViewInit {

  @Input() controlId = null;
  @Input() disabled = false;
  @Input() required = false;
  @Input() height = 300;
  @Input() controlPlaceHolder = '';
  @Input() className = '';
  @ViewChild('file') file: ElementRef;
  @ViewChild('editor') editor: ElementRef;

  @Output() inputChange: EventEmitter<any> = new EventEmitter();

  reachMaximum = false;
  invalidExtension = false;
  maximumFileSizeInMb = 5;

  config: AngularEditorConfig = {
    editable: !this.disabled,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    sanitize: false,
    upload: (file: File) => {
      this.handleUploadImage(file)
      return null
    },
    toolbarHiddenButtons: [
      [
        'bold',
        'redo',
        'strikeThrough',
        'subscript',
        'superscript',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
        'backgroundColor',
        'customClasses'
      ],
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  };

  constructor(
    private http: ApplicationHttpClient,
    private toastService: ToastService
  ) {
    super();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    setTimeout(() => {
      const eyeDroppers = document.querySelectorAll('.colorpick-eyedropper-input-trigger');
      // @ts-ignore
      eyeDroppers.forEach(item => item.style.display = 'none');
    }, 200)
  }

  getIcon(fileExtension = '') {
    const iconField = {
      'pdf': `<img class="wrapUploadImage" style="padding-right: 10px" src=${IMAGE_BASE64.pdf} alt="pdf">`,
      'xlsx': `<img class="wrapUploadImage" style="padding-right: 10px" src=${IMAGE_BASE64.xlsx} alt="xlsx">`,
      'xls': `<img class="wrapUploadImage" style="padding-right: 10px" src=${IMAGE_BASE64.xlsx} alt="xlsx">`,
      'pptx': `<img class="wrapUploadImage" style="padding-right: 10px" src=${IMAGE_BASE64.pptx} alt="ppt">`,
      'ppt': `<img class="wrapUploadImage" style="padding-right: 10px" src=${IMAGE_BASE64.pptx} alt="ppt">`,
      'docx': `<img class="wrapUploadImage" style="padding-right: 10px" src=${IMAGE_BASE64.docx} alt="docx">`,
      'doc': `<img class="wrapUploadImage" style="padding-right: 10px" src=${IMAGE_BASE64.docx} alt="docx">`
    };
    return iconField[fileExtension] || '';
  }

  async uploadPdf(event) {
    this.reachMaximum = false;
    this.invalidExtension = false;

    const file = event.target.files[0];
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop();
    const validExtensions = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf'];
    const isValidExtension = validExtensions.includes(fileExtension);
    const fileSizeInMB = file.size/(Math.pow(1024, 2));
    const isReachMaximum = fileSizeInMB >= this.maximumFileSizeInMb;

    if (!isValidExtension) {
      return this.invalidExtension = true;
    }

    if (isReachMaximum) {
      return this.reachMaximum = true;
    }

    const icon = this.getIcon(fileExtension);
    let fileGeneratedUrl = '';
    let fileGeneratedName = '';

    Common.showLoading();

    try {
      const base64File = await Common.getBase64(file)
      const body = {
        FileContent: base64File,
        FileName: file.name,
        ContentType: file.type,
        ContentLength: file.size
      }

      const response = await this.http.post(`/api/admin/posts/upload-document`, body).toPromise();
      fileGeneratedUrl = response.imageUrl;
      fileGeneratedName = response.originalName;
      Common.hideLoading();
    } catch (error) {
      Common.hideLoading();
      this.file.nativeElement.value = null;
      const [errorMessage] = error.error && error.error[0] && error.error[0].Messages;
      return this.toastService.error(errorMessage);
    } finally {
      Common.hideLoading();
    }

    const link =
      '<div class="wrapUpload" style="padding: 10px; border-radius: 3px; background-color: #F9F8FD; margin: 10px 0; display: flex; align-items: center">'
        + '<div>' + icon + '</div>'
          +'<div class="wrapUploadContent">'
            +'<a' + ' ' + 'class="wrapLink"' + ' ' + 'style="color: #1C2D41; font-weight: bold"' + ' ' +  `href="${ fileGeneratedUrl }"` + ' ' + 'target="_blank"' + '>'
              + fileGeneratedName +
            '</a>'
            + '<p class="wrapSizeText" style="font-weight: normal; margin-bottom: 0">' + (fileSizeInMB * 1024).toFixed(1) + 'KB' + '</p>' +
          '</div>' +
      '</div>' + '<br>';

    if (!this.value) {
      this.value = link;
    } else {
      this.value = this.value + link;
    }
    this.file.nativeElement.value = null;
  }

  async handleUploadImage(file) {
    Common.showLoading();
    const formData = new FormData();
    formData.append('file', file);
    let fileGeneratedUrl = '';

    try {
      const base64Image = await Common.getBase64(file)
      const body = {
        FileContent: base64Image,
        FileName: file.name,
        ContentType: file.type,
        ContentLength: file.size
      }

      const responseImages = await this.http.post(`/api/admin/posts/upload-image`, body).toPromise();
      fileGeneratedUrl = responseImages.imageUrl
    } catch (error) {
      Common.hideLoading();
      this.file.nativeElement.value = null;
      const [errorMessage] = error.error && error.error[0] && error.error[0].Messages;
      return this.toastService.error(errorMessage);
    } finally {
      Common.hideLoading();
    }

    const imageTag = `<img src="${fileGeneratedUrl}" />`

    if (!this.value) {
      this.value = imageTag;
    } else {
      this.value = this.value + imageTag;
    }
    this.file.nativeElement.value = null;
  }
}
