import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ui-fieldset',
  templateUrl: './fieldset.component.html',
  styleUrls: ['./fieldset.component.scss']
})
export class UIFieldsetComponent implements OnInit {
  @Input() title = '';
  @Input() background: 'white' | 'light' = 'white';

  constructor() { }

  ngOnInit() {
  }

}
