import {
  Component, ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output, Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessorEnabler } from '../../entities/control-value-accessor-enabler';
import { LabelValuePair } from '../../interfaces/label-value-pair';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UIDropdownComponent),
  multi: true
};


@Component({
  selector: 'app-ui-dropdown',
  templateUrl: 'dropdown.component.html',
  styleUrls: ['../ui-elements.scss', 'dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class UIDropdownComponent extends ControlValueAccessorEnabler implements OnInit, OnChanges {

  @Input() icon: 'clock-o' | 'chevron-down' | 'calendar-o' = 'chevron-down';
  @Input() disableOptionSelected = false;
  @Input() rounded = true;
  @Input() controlId = '';
  @Input() controlPlaceHolder = null;
  @Input() readOnly = false;
  @Input() options: Array<LabelValuePair> = [];
  @Input() confirmBeforeChangeStatus;
  @Input() fallbackOption = null;
  @Output() optionsChange = new EventEmitter<Array<LabelValuePair>>();
  @Output() selectItemEvent: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('dropdown') dropdownRef: ElementRef;
  @ViewChild('uiDropdown') uiDropdownRef: ElementRef;

  showOptions = false;
  listenForOutsideClick = false;

  constructor(
    private renderer: Renderer2
  ) {
    super();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.showOptions = false;
    this.listenForOutsideClick = false;
  }

  toggleOptionsDropdown() {
    this.listenForOutsideClick = true;
    this.showOptions = !this.showOptions;

    if (this.showOptions) {
      const uiDropdown = this.uiDropdownRef.nativeElement.getBoundingClientRect();
      this.renderer.setStyle(this.dropdownRef.nativeElement, 'top', `${ uiDropdown.bottom + 2 }px`);
      this.renderer.setStyle(this.dropdownRef.nativeElement, 'left', `${ uiDropdown.left }px`);
      this.renderer.setStyle(this.dropdownRef.nativeElement, 'min-width', `${ uiDropdown.width }px`);
    }
  }

  onClickOutsideComponent() {
    this.showOptions = false;
    this.listenForOutsideClick = false;
  }

  selectOption(option: LabelValuePair, disableValue) {
    if (disableValue) {
      return;
    }

    if (option.value !== this.value) {
      this.value = option ? option.value : null;
      this.listenForOutsideClick = false;
      this.selectItemEvent.emit(option ? option.value : null);
    }
  }

  checkValue() {
    return typeof this.value !== 'boolean';
  }
}
