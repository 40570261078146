import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BAD_REQUEST, UNAUTHORIZED } from 'http-status-codes';
import { AuthService } from '../../modules/auth/auth.service';
import { ToastService } from '../../shared/services/toast.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private toastService: ToastService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      const pathName = window.location.pathname;
      const { status } = err;

      if (status === UNAUTHORIZED && !pathName.includes('/auth/password/forgot') && !pathName.includes('/auth/password/reset')) {
        this.authService.logout();
        this.router.navigate(['/auth/login']);
      } else if (status === BAD_REQUEST && pathName.includes('/posts/create')) {
        this.toastService.error(err.error[0].Messages[0]);
      } else {
        return throwError({ status: err.status, error: err.error });
      }
    }));
  }
}
