import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomEventBroadcasterService } from '../../services';
import { ControlValueAccessorEnabler } from '../../entities/control-value-accessor-enabler';
import { CustomEventDetails } from '../../interfaces';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UIRadioComponent),
  multi: true
};

@Component({
  selector: 'app-ui-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss', '../ui-elements.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class UIRadioComponent extends ControlValueAccessorEnabler implements OnInit, OnDestroy {

  private defaultInputIdentifier = 'ui-form-control' + new Date().getTime().toString();

  @Input() name: string = this.defaultInputIdentifier;
  @Input() controlId = '';
  @Input() disabled = false;
  @Input() radioValue: string;
  @Input() controlLabel: string;

  @Output() selectedOptionChange = new EventEmitter<string>();

  private eventBroadcasterSubscription: Subscription;

  constructor(private eventBroadcaster: CustomEventBroadcasterService) {
    super();
  }

  ngOnInit() {
    this.eventBroadcasterSubscription = this.eventBroadcaster.subscribeFor(
      'uiRadio:selected',
      (eventDetails: CustomEventDetails) => {
        if (eventDetails.details.group === this.name && this.radioValue !== eventDetails.details.uiRadio.radioValue) {
          this.value = eventDetails.details.uiRadio.radioValue;
        }
      });
  }

  selectRadio() {
    this.eventBroadcaster.broadcastEvent(
      'uiRadio:selected',
      {
        details: {
          group: this.name,
          uiRadio: this
        }
      }
    );

    this.selectedOptionChange.emit(this.radioValue);
    this.value = this.radioValue;
  }

  get isSelected(){
    return this.value === this.radioValue;
  }

  ngOnDestroy() {
    this.eventBroadcasterSubscription.unsubscribe();
  }

}
