import { Component, OnInit, Input, OnChanges, SimpleChanges, HostBinding } from '@angular/core';

const STYLE_CLASS = {
  blue: 'ui-card--blue',
  indigo: 'ui-card--indigo',
  green: 'ui-card--green',
  pink: 'ui-card--pink',
};

@Component({
  selector: 'app-ui-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class UICardComponent implements OnInit, OnChanges {

  @Input() number = null;
  @Input() text = '';
  @Input() boxColor: 'indigo' | 'blue' | 'green' | 'pink' | 'red' = 'indigo';

  styleClass: string = STYLE_CLASS[this.boxColor] || null;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('boxColor')) {
      this.styleClass = STYLE_CLASS[this.boxColor] || null;
    }
  }
}
