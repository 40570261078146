import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ui-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class UINotificationComponent implements OnInit {
  @Input() totalNotification = 4;

  constructor() { }

  ngOnInit() {
  }

}
