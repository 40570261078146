import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { TableColumn } from '../interfaces/table-column';

@Directive({
  selector: '[tableAutoSize]'
})
export class TableAutoSizeDirective implements OnInit {
  @Input('tableAutoSize') columns: TableColumn[] = [];
  @Input() autoSize = false;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef) {
  }

  ngOnInit() {
    const size = this.columns.reduce(
      (total, { width }) => {
        const w = width !== undefined ? width : 0;
        return total + w;
      }, 0);

    if (this.autoSize) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'width', `100%`);
    } else {
      this.renderer.setStyle(this.elementRef.nativeElement, 'width', `${size}px`);
    }
  }
}
