import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TableColumn } from '../../interfaces/table-column';
import { TableSortItem } from '../../interfaces/table-sort-item';
import { LabelValuePair } from '../../interfaces/label-value-pair';

@Component({
  selector: 'app-ui-table',
  templateUrl: 'table.component.html',
  styleUrls: ['ui-table.scss', 'table.component.scss']
})
export class UITableComponent implements OnInit, OnChanges {

  @Input() confirmBeforeChangeStatus;
  @Input() tableId: string;
  @Input() items: Array<any> = [];
  @Input() sortDefault: string;
  @Input() dropdownOptions: Array<LabelValuePair> = [];
  @Input() dropdownPlaceHolder: string;
  @Input() autoSize = true;
  @Input() readonly uiTotal: number;
  @Input() readonly uiPageSize = 20;
  @Input() uiPageIndex: number;
  @Output() itemsChange = new EventEmitter<Array<any>>();
  @Output() sortClickedEvent: EventEmitter<TableSortItem> = new EventEmitter<TableSortItem>();
  @Output() selectItemEvent: EventEmitter<any> = new EventEmitter<{}>();
  @Output() rowEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() uiPageIndexChange = new EventEmitter();

  @Input() set columns(columns: Array<TableColumn>) {
    if (columns) {
      this._columns = columns;
      this.columnKeys = columns.map(column => column.translation);
      this.columnsConfig = columns.reduce((n, val) => {
        n[val.translation] = val;
        return n;
      }, {});
    }
  }

  get columns() {
    return this._columns;
  }

  private _columns: Array<TableColumn> = [];
  columnsConfig: { [key: string]: TableColumn } = {};
  columnKeys: Array<string> = [];

  constructor() {
  }

  ngOnInit() {
  }

  sortByField(column: TableSortItem) {
    if (this.sortClickedEvent.observers.length > 0) {
      this.sortClickedEvent.emit(column);
    }
  }

  selectItem(rowItem: any, index: number) {
    this.selectItemEvent.emit({ rowItem, index });
  }

  handleRowEvent(event) {
    if (this.rowEvent.observers.length > 0) {
      this.rowEvent.emit(event);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  pageChanged(event: number) {
    this.uiPageIndexChange.emit(event);
    this.uiPageIndex = event;
  }
}
