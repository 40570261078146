import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

@NgModule({
  declarations: [DateFormatPipe, SafeHtmlPipe],
  imports: [
    CommonModule
  ],
  exports: [DateFormatPipe, SafeHtmlPipe],
  providers: [DatePipe]
})
export class CoreModule { }
