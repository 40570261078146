import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorEnabler } from '../../entities/control-value-accessor-enabler';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UIInputImageComponent),
  multi: true
};

@Component({
  selector: 'app-ui-input-image',
  templateUrl: './input-image.component.html',
  styleUrls: ['./input-image.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class UIInputImageComponent extends ControlValueAccessorEnabler implements OnInit {
  private defaultInputIdentifier = 'ui-form-control' + new Date().getTime().toString();

  @Input() notificationThumbnail = false;
  @Input() controlId = null;
  @Input() controlPlaceHolder = '';
  @Input() readOnly = false;
  @Input() name: string = this.defaultInputIdentifier;

  @ViewChild('file') file: ElementRef;
  public imagePath;
  public message: string;
  reachedMaximum = false;
  imgURL: any;
  LIMIT_SIZE_UPLOAD = 5;

  constructor() {
    super();
  }

  ngOnInit() {
  }

  preview(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    const fileSize = files[0].size / (1024 * 1024);
    if (mimeType.match(/image\/*/) == null || mimeType === 'image/svg+xml') {
      this.message = 'shared.components.ui.inputImage.onlyImagesAreSupported';
      this.reachedMaximum = false;

    } else if (fileSize > this.LIMIT_SIZE_UPLOAD) {
      this.reachedMaximum = true;
      return;
    } else {
      this.reachedMaximum = false;
      this.value = files[0];
      this.message = files[0].name;
      this.imagePath = files;

      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (event) => {
        this.imgURL = reader.result;
      };

    }

  }

  onClose(defaultThumbnail = null) {
    this.imagePath = null;
    this.imgURL = null;
    this.message = null;
    this.value = defaultThumbnail || null;
    this.file.nativeElement.value = null;
  }
}
