import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import {
  LayoutComponent,
  AuthLayoutComponent,
  HeaderComponent,
  FooterComponent,
  AppBodyComponent,
  AppMainComponent,
  AppContentComponent,
  AppSidebarComponent,
  AppSidebarNavLinkComponent,
  AppSidebarNavTitleComponent,
  ForgotPasswordLayoutComponent,
} from './layouts';

import {
  UIButtonComponent,
  UIInputComponent,
  UICheckboxComponent,
  UIButtonLinkComponent,
  BreadcrumbComponent,
  UIDropdownComponent,
  UITableComponent,
  UITableHeaderComponent,
  UITableRowComponent,
  UICardComponent,
  UITextareaComponent,
  UIFieldsetComponent,
  UIRadioComponent,
  UIRichtexteditorComponent,
  UIDatetimePickerComponent,
  UIInputImageComponent,
  UINotificationComponent,
  UIUserInfoComponent,
  UIErrorMessageComponent,
  UIToastComponent,
  UIToggleButtonComponent,
  UIMultiSelectComponent,
  UIFormGroupComponent,
  UILabelComponent
} from './components/ui';

import {
  LabelValuePairPipe
} from './components/pipes';

import {
  OutsideClickListenerDirective,
  TableAutoSizeDirective,
  MaxLengthDirective,
} from './components/directives';

import { NgxPaginationModule } from 'ngx-pagination';
import { DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ModalServiceModule } from './modal/modal-service.module';
import { DropdownButtonComponent } from './components/ui/dropdown-button/dropdown-button.component';
import { TrimTextDirective } from './components/directives';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CoreModule } from '../core/core.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxPaginationModule,
    DateTimePickerModule,
    NgMultiSelectDropDownModule,
    AngularEditorModule,
    ModalServiceModule.forRoot(),
    NgxPermissionsModule,
    CoreModule
  ],
  declarations: [
    LayoutComponent,
    AuthLayoutComponent,
    HeaderComponent,
    FooterComponent,
    AppBodyComponent,
    AppMainComponent,
    AppSidebarComponent,
    AppContentComponent,
    AppSidebarNavLinkComponent,
    AppSidebarNavTitleComponent,
    LabelValuePairPipe,
    OutsideClickListenerDirective,
    TableAutoSizeDirective,
    MaxLengthDirective,
    UIButtonComponent,
    UIInputComponent,
    UICheckboxComponent,
    UIButtonLinkComponent,
    BreadcrumbComponent,
    UIDropdownComponent,
    ForgotPasswordLayoutComponent,
    UITableComponent,
    UITableHeaderComponent,
    UITableRowComponent,
    UICardComponent,
    UITextareaComponent,
    UIFieldsetComponent,
    UIRadioComponent,
    UIRichtexteditorComponent,
    UIDatetimePickerComponent,
    UIInputImageComponent,
    UINotificationComponent,
    UIUserInfoComponent,
    UIErrorMessageComponent,
    UIToastComponent,
    UIToggleButtonComponent,
    UIMultiSelectComponent,
    DropdownButtonComponent,
    TrimTextDirective,
    UIFormGroupComponent,
    UILabelComponent
  ],
  exports: [
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    OutsideClickListenerDirective,
    LabelValuePairPipe,
    UIButtonComponent,
    UIInputComponent,
    UICheckboxComponent,
    UIButtonLinkComponent,
    BreadcrumbComponent,
    UIDropdownComponent,
    UITableComponent,
    UICardComponent,
    UITextareaComponent,
    UIFieldsetComponent,
    UIRadioComponent,
    UIRichtexteditorComponent,
    UIDatetimePickerComponent,
    UIInputImageComponent,
    UINotificationComponent,
    UIUserInfoComponent,
    UIErrorMessageComponent,
    UIToastComponent,
    UIToggleButtonComponent,
    UIMultiSelectComponent,
    UIFormGroupComponent,
    UILabelComponent
  ],
  entryComponents: [],
})
export class SharedModule {
}
