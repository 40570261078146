import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  config = {
    timeOut: 3000,
    progressBar: true,
    messageClass: 'toast-message'
  };

  constructor(
    private toastrService: ToastrService
  ) { }

  success(message= '', title= '') {
    this.toastrService.success(message, title, this.config);
  }

  error(message = '', title = '') {
    this.toastrService.error(message, title, this.config);
  }
}
