
import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-main',
  template: '<ng-content></ng-content>'
})
export class AppMainComponent {
  @HostBinding('class.main') mainClass = true;
  @HostBinding('class.bg-light') bgLightClass = true;
}
