import { Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { ControlValueAccessorEnabler } from '../../entities/control-value-accessor-enabler';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UITextareaComponent),
  multi: true
};

@Component({
  selector: 'app-ui-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class UITextareaComponent extends ControlValueAccessorEnabler implements OnInit {
  private defaultInputIdentifier = 'ui-form-control' + new Date().getTime().toString();
  private defaultTextareaRows = 3;

  @Input() controlId = null;
  @Input() controlPlaceHolder = '';
  @Input() readOnly = false;
  @Input() pattern: any = '';
  @Input() disabled = false;
  @Input() required = false;
  @Input() name: string = this.defaultInputIdentifier;
  @Input() maxLength = null;
  @Input() rows = this.defaultTextareaRows;
  @Input() class = '';

  @Output() inputChange: EventEmitter<any> = new EventEmitter();

  ngControl: NgControl;
  usedCharacters = 0;

  constructor(private injector: Injector) {
    super();
  }

  ngOnInit() {
    this.ngControl = this.injector.get(NgControl);
    setTimeout(() => {
      this.ngControl.valueChanges.subscribe((value) => {
        this.calculateRemainLength();
      });
    });
  }

  onChange(value) {
    this.inputChange.emit(value);

    if (this.displayRemainingLength) {
      this.calculateRemainLength();
    }

  }

  calculateRemainLength() {
    this.usedCharacters = (this.ngControl.control.value || '').length;
  }

  displayRemainingLength() {
    return Boolean(this.maxLength);
  }

}
