export const STORAGE = {
  REFRESH_TOKEN_TIME: 'REFRESH_TOKEN_TIME',
  CREDENTIAL_STORAGE: 'CREDENTIALS',
  USERNAME: "USERNAME"
};

export const SORT_BY = {
  DEFAULT: 'default',
  ASCENDING: 'asc',
  DESCENDING: 'desc'
};

export const PAGE_DEFAULT = {
  PAGE: 1,
  SIZE: 20,
  SORT: ''
};

export const DATE_FORMAT = 'yyyy/MM/dd';
export const DATE_FORMAT_TIME = 'yyyy/MM/dd h:mm:ss';

export const LIST_STATUS = [
  {
    code: 0,
    title: 'Draft'
  },
  {
    code: 1,
    title: 'Active'
  },
  {
    code: 2,
    title: 'Published'
  },
  {
    code: 3,
    title: 'Unpublished'
  },
  {
    code: 5,
    title: 'Disapprove'
  },
  {
    code: 6,
    title: 'Waiting to Submit.'
  }
];

export const IMAGE_BASE64 = {
  xlsx: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKtSURBVHgB7VdNbhoxFH42P+6m6vQEZdsUWjhB6SIl6ob2BnCC8tdsSVaRIpFppe6TGwA7IAvgBIBEyDLpCUo3FTNT/PpMmYoMAWaGqpHafJIZPH62Pz+/99kD8L+DuTU86CW1h6HQW7f23LJq+URnvMnOFYGj3l5EhLENiDQg2zgojZqk3z4zjVebSATBBUIBGadRB4Voa6MH9Mu9CEq8AgldDIu23kuuJcHBBRhHjVz1FTygEGvmJOJgTkKDbQj4RSnayigSMix0uAsCNglEZJWL1CncBQGFgGXmGGD8NhKugtAPjnrJiP1/okggZoOMVSujlF7caebttj/vgclkTOnaFxR8iyXIeFU1M4Tc59GbJ7Z5cBP7WSfOH0kAzfneCQNgPE+5xCqbk4vU1WJ9iYBOLqKgydwQHPylWGolsAYCUNMvX3/MP20dgkssEUByETPNx25k1Im5CCmSrgncGgN+JveLlVmg1AuFqDLDeAdClKeAXYYsXXzWzNo2J6NUW20NxUd/MbK9YGUWKC+ghLqSUooBrbTTqtFkSbv9uLdL5wNELCnzxODLOrn1RUDBtFiNHvEpw7qzbT9x3kfJDoPAdcbgPfjEWiEKh7GsJuEcyrTCDiXD7xQNQEgDjmnDMLJCiNMfwQdxGuwaPGKlB46H5GKEQTHWOCAp/6QmkAj1cEiUVYEQGUk2q6ut+hBrdMAHVnpgP3rep4cqUIw2zuavOw4z1X4GW2CZAN16KsNUjpbnPRURX5Bieeq3REBKyDIO6cXdoXqEHiQySx5w9qVeMgvbECg9b6nIry2+qwx3M5zxl4Vo09PgbvBX7gP3BNbB9Y0I2SwQtweiZpnfv9lVVx8m84OpBz6U7ubkoFHpUjDnPBGYkaCzfmrJON2OfB06CqSk134V89/FTwCUE/IBI553AAAAAElFTkSuQmCC',
  docx: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAcCAYAAAB/E6/TAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKeSURBVHgB3VbNchJBEO6eWdiT5foEck0kJXkClyoTUl6MT5DkCcIm6jV6SpVKSKq8Wz6BegvxEPIEgBJyBJ9AvFj8yLTdK0SCu7AQueQ7MLsz2/NNd3/dA0IIXpRc51Ystg4Robrdj95ysRm2jkGT+6W1hB2nUyBiQww1HtrF5d8ydtrpMDIraDKmTYqtKzvJk4ke5S/WEmSoDgbOKG6f5ktuIJkKMkZFDrv6HabAzlIha4gqfTInEtGseJo82RQyE7fzcyUakBER5s4z7+ZKJNDdThaBUsNkFvwn7JfcxOC5JWREWxbih1wtk99dLHjXJ2q1mhCLl20WQdAyEmTf1h4dWqOn8ReVum0AnNH5UbQBmn0pL4d9c3Ceqcto5dk1Tt7mlcKkP5UcdsoBbCAnf7F66C2cvIQJsIhdw07nzrj2EYZ+scphJhL5qpuFZFpciiFXzWQRaQMQmzwWJRwHtcw2SFh57pcx3vPk5zKHag8IXUPQgF7viBMaiejvV0QO5+oI2+0n1IPsq+oK9zs/rGlEZKmq0zdfVtfBYMJbLKQ5jxXQ2oGI+Oc4fhgVljVASjaTd2/huMFLTemBxtA3+W43WTjkoQERcaWOiGX9+utD1z+B1kVO9J54huytyNAY3dCaNmROo9ozPfiEGI3o0iPSfJ8Y4A30OnI+xAtUmNagtv05xPSzpWMmx/cyx96eKQ1FiAouKIIZIfIeFOSY/evcGe5acouK4jgw00uc6D6HNJKdZQxssUIfD+uC3xM8cDGODw3bspXZgggITGWuurKpUD3w7hUibTIOEro4ancu91EQbh5R6MVH6Avi+uBi73Z+/ggUg/xdItsuwRQtJpgEHCnsnWQhG9pApBh7XZPi2zZy4xyFdHjpJvL8G+d1KAqm5XyjAAAAAElFTkSuQmCC',
  pptx: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJ0SURBVHgB7VexctpAEN07CVRlonxB1MbBCf6C4CLBkybxH8AfACZ14soVlov0yR9gdxEN8AWYGRuX5g9Cmgyg6DZ7BI0FItJJ4Hgm9psR6HR7e093u29PAPcdTNXwU69gPspk3qvac9c9re50RnF2SgSOenuWkcU2IJJDFuuUvBbo95xNJ7txJHRQQEYTefLar+VasStgX+1ZKPAaBHQxa7TtXiGSBAcFMI4mLdV3SIDatlMRiP05CRPWIZAW9VyrJEmIrGHDXRDwSSAia1wWv8BdEJDQ3GmFAeZXkVAKwjQ46hUs/34sSSCWdcaajUHRPthyqn7f5ldgPB5Rup4bFHzBS2e8KbsZQuXz4O1T31yPYz8bxPljAWAuP1/GBGA0T7mdv9kcXxavg+0QAZuWiIKmtCA4+Eex5JtABAxA0756c1J91joERYQIIC0Rm06fqMjoMuYiJEkqE1gZA2kmT4vILDgezPZrKO/ZZLKPhtGkIBohA9Pz2KGu4UfaHYu2Z/iL2r5tEkRnAXmvbTm74EFfyEqIYFWfO/voYZeDKFRlH02aAa30YftbB1IgLg0tCso25U6ea1pHPpBtRtWO6/wrbABxQjScv+UMlEIQbN86AcqI02BbIJyFbAScue7PH3AbBA5yN5IpUc85lRU2J7AGwgTo1NO4KNJEInkqIr4kxUo0LkRACCgzDu+C8Ulti/5IZKAT5YzG0ihRhnUI1F+05L4v7H3j4nWJM/6qlnMSOVfBPzkPPBCIgvKJiPTfgk0A0XSnN7qh9GEij9VUiHqQotgsTg4mXd1aQE+UP81krfdckafTkQkpQUo6TFu0/l/8BkWn/GLhRzl6AAAAAElFTkSuQmCC',
  pdf: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKYSURBVHgB7Ve7ctpAFL1XAlRlLH9BaOOBBH+BcZHgSePkCwJ/wCs1uHLFo0if5AuwO0MDfAEwg3Fp/iCkySCI9uYuQRMZHGklMnFhnxk9Vvs6ex9nVwCPHajasDpIm8+i0Xeq7bXl8qJw2Jv5tVMicD44iRsx6gIRD4i+g/Koab4PcWEd+5GIgAKiukjxqKNiouNrgcbNSZwE3YKAPsWMbmOQ9iShgQJQI5NN9Q0CoJhs5wXRaE3ChF0IhEU50clKEiJmNOAhCDgkiAhr15nP8BAEJPTlIo9AqftIKAVhGJwP0nHnfS5JEOUiiK3aJNMoHbQLTt2/t8B8PuN0HRocfO4rglpLViNB/tPk7XOnecSP/aqTpu0JAHPz+yYsgNk65Q7/1qZ+nbl1l7cINNhEHDTZO4JDvxVLrgQ8YACZjZs3zcKLzhkoYosAsYlwsdhXkdFNrEVIklQmcG8MhJk8LDyzoD5Z+Wsq39Gy3pNhtJw6y8KclGhNhwoizoSNff78BQLCOwvY98WD9jHYMBJyJySIy7Jt4xlvTk0p0STgssDfSsmrKoSAnw7EOSi77FfUdL3Jz8qqUwSmZMOefEcdP9THmaOfIGRuB3adH4GpXJ1T4BRapShnySkik2ALkU1fS8lOVdbLIISA8HQBT3DhLguCy1jUqJCN+7CwCoL0KekwhB3gaYFS4o9kSpQT7fxGkx7siG0CfOqpjTM8kQieikSvWLEC9dsiIATkUINTt3e4HOcHi4z3irkv9xI52IVA+WVH+v2O72vj11kNtaNioh1ocBX8l/PAEwEvKJ+ICFeBuDuIzOXix3enqPRjIo/VvBENYL0xhZ8cTL76RZeeKP+aSZm1lyLFpyMTQoKVdPoxedWDJ7jwC3K7E6UPLGQfAAAAAElFTkSuQmCC'
}

