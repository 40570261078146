import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessorEnabler } from '../../entities/control-value-accessor-enabler';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UICheckboxComponent),
  multi: true
};

@Component({
  selector: 'app-ui-checkbox',
  templateUrl: 'checkbox.component.html',
  styleUrls: ['../ui-elements.scss', 'checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class UICheckboxComponent extends ControlValueAccessorEnabler implements OnInit {
  private defaultInputIdentifier = 'ui-form-control' + new Date().getTime().toString();

  @Input() controlId = null;
  @Input() name: string = this.defaultInputIdentifier;
  @Input() disabled = false;
  @Input() controlLabel = '';

  @Output() isSelectedChange = new EventEmitter<boolean>();

  constructor() {
    super();
  }

  ngOnInit() {
  }

  toggleCheckboxSelection() {
    const isSelected = Boolean(this.value);
    this.value = !isSelected;
    this.isSelectedChange.emit(isSelected);

  }

}
